
import Vue from "vue";
import Component from "vue-class-component";

import Divider from "@/components/ewa/common/Divider.vue";
import { StoreActionTypes } from "@/store/types";

@Component({
  components: {
    Divider,
  },
  watch: {
    switchUnlim: "setDefaultCurrentTariff",
  },
})
export default class EwaTariffsModal extends Vue {
  async mounted() {
    await this.$store.dispatch(StoreActionTypes.GET_TARIFFS);
    this.setDefaultCurrentTariff();
  }

  public tariffExamples = [
    {
      mounthCount: 1,
      name: "месяц",
      price: 400,
    },
    {
      mounthCount: 3,
      name: "месяца",
      price: 300,
    },
    {
      mounthCount: 6,
      name: "месяцев",
      price: 200,
    },
    {
      mounthCount: 12,
      name: "месяцев",
      price: 100,
    },
  ];

  public currentTariff = {
    id: 0,
    price: 0,
    countMonth: 0,
  };

  public switchUnlim = false;

  public couponCode = null;

  public couponCodeDisabled = false;

  get tariffs() {
    return this.$store.getters.getTariffs
      .filter((v) => !v.limited == this.switchUnlim)
      .sort((a, b) => b.countMonth - a.countMonth);
  }

  get userBalance() {
    return this.$store.getters.getBalance;
  }

  get isLoading() {
    return this.$store.getters.ewaDialogIsLoading;
  }

  setDefaultCurrentTariff() {
    if (!this.currentTariff.price) {
      this.currentTariff = this.tariffs[0];
      return;
    }

    this.currentTariff = this.tariffs.find(
      (v) => v.countMonth == this.currentTariff.countMonth
    );
  }

  chooseTariff(tariff) {
    this.currentTariff = tariff;
  }

  async updateTariffs() {
    if (!this.couponCode || this.couponCode.length == 0) {
      return;
    }
    await this.$store.dispatch(StoreActionTypes.GET_TARIFFS, this.couponCode);
    this.setDefaultCurrentTariff();
    this.couponCodeDisabled = true;
  }

  async createInstance() {
    await this.$store.dispatch(StoreActionTypes.CREATE_INSTANCE, {
      tariffId: this.currentTariff.id,
      couponCode: this.couponCode,
    });
    await this.$store.dispatch(StoreActionTypes.GET_USER_INSTANCES);
    this.close();
  }

  openPaymentDialog() {
    (this.$root as any).openDialog(
      {
        component: {
          is: () => import("@/components/ewa/modals/Payment.vue"),
          _name: "Payment",
        },
        settings: {
          position: "center",
        },
      },
      true
    );
  }

  close() {
    (this.$root as any).closeDialog();
  }
}
